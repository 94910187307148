<template lang="html">
  <Modal closePosition="visible">
    <div class="p-6 relative root">
      <div
        v-if="states[currentState]"
        v-html="states[currentState]"
        class="rounded-lg text-white text-sm w-full py-3 px-4 -top-14 left-0 absolute"
        :class="[currentState < 500 ? 'bg-green' : 'bg-red']"
      />

      <h2 class="font-h font-semibold text-primary text-2xl">
        Admin Control
      </h2>

      <div class="mt-2 text-sm text-primary">
        Invite members & add minutes to their accounts
      </div>

      <input
        placeholder="Invite Someone"
        type="text"
        @keyup.enter="inviteEmail"
        v-model="email"
        class="border rounded-lg bg-primary-100 border-primary-200 mt-4 text-primary text-sm w-full py-2.5 px-4 placeholder-primary-500 resize-none focus:ring-primary focus:border-primary-200 focus:ring-2"
      >

      <div class="flex mt-2.5 justify-end">
        <Button
          classname="pr-1.5"
          size="small"
          @click="inviteEmail"
         >
          Invite <ChevronRight classname="h-3 w-3 ml-0.75"/>
        </Button>
      </div>

      <div v-if="Object.keys(team).length>0" class="border-t-2 border-primary-200 mt-3 max-h-60 pt-2 pr-2 overflow-y-auto">
        <table class="w-full">
          <tr class="font-h font-semibold text-primary">
            <td class="text-lg w-4/10">Members</td>
            <td class="text-sm w-2/10">Balance</td>
            <td class="w-4/10"/>
          </tr>
          <tr :key="index" v-for="(i,index) in team">
            <td class="h-10 text-xs text-primary">{{i.email}}</td>
            <td v-if="!i.verified" class="text-xs text-primary"/>
            <td v-else class="text-xs text-primary">{{humanLength(i.balance)}}</td>
            <td v-if="active[i.email]>=30">
              <div class="flex items-center justify-end">
                <v-popover
                  offset="4"
                  placement="bottom"
                  popoverClass="popover-sm"
                  popoverArrowClass="hidden"
                >
                  <div class="border rounded cursor-pointer flex bg-primary-100 border-primary-300 text-sm text-primary py-2.25 px-4 w-36 items-center justify-between">
                    <div class="text-sm">{{active[i.email]}} Minutes</div>
                    <ChevronDown class="h-3 w-3 dropdownCaret"/>
                  </div>

                  <template slot="popover">
                    <div class="max-h-36 py-1.5 pr-0.5 pl-1.5 w-36 overflow-y-auto">
                      <div
                        v-for="(value,key) in thirtyMinute"
                        :key="key"
                        :value="key"
                        @click="active[i.email]=value;"
                        v-close-popover
                        class="rounded cursor-pointer text-sm text-primary mb-2 py-2.5 pr-2.5 pl-9 relative last:mb-0 hover:bg-primary-200"
                      >
                        {{value}} Minutes
                        <CheckIcon v-if="active[i.email] == value" class="h-5 top-2.5 left-1.5 w-5 absolute"/>
                      </div>
                    </div>
                  </template>
                </v-popover>

                <Button
                  size="small"
                  @click="addMinute(i.email,i.uid,active[i.email])"
                  class="ml-3"
                >
                  Add
                </Button>

                <div @click="setInActive(i.email)" class="cursor-pointer ml-3 hover:text-red tex-primary-300">
                  <CloseIcon class="h-5 w-5" />
                </div>
              </div>
            </td>
            <td v-else-if="active[i.email]==-1" class="text-sm text-primary-700">
              Loading..
            </td>
            <td v-else class="flex h-10 items-center justify-end">
              <Button
                size="small"
                @click="setActive(i.email)"
                v-if="i.verified"
              >
                Add Minutes
              </Button>
              <div
                v-else
                class="text-xs text-primary-400"
              >
                Invite not accepted yet
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as firebase from 'firebase/app'
import 'firebase/database'
import CONSTANTS from '@/constants/CONSTANTS'
import axios from 'axios'
import store from '@/services/store'
import { humanLength } from '@/utilities/utility'

// import Status from '@/components/base/status/index.vue'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'
import ChevronRight from '@/components/base/icons/ChevronRight.vue'
import CloseIcon from '@/components/base/icons/CloseCircle.vue'
import ChevronDown from '@/components/base/icons/ChevronDown.vue'
import CheckIcon from '@/components/base/icons/Check.vue'

export default {
  components: {
    Modal,
    Button,
    ChevronRight,
    ChevronDown,
    CloseIcon,
    CheckIcon
  },
  data () {
    return {
      email: '',
      states: {
        200: 'Invited Succesfully!',
        201: 'Minutes added successfully',
        500: 'Email empty',
        501: 'Please enter a valid e-mail ID',
        502: 'Already added',
        503: 'Unable to Invite',
        504: 'Already invited',
        505: `Can't invite yourself`,
        506: '',
        507: 'Network Error',
        508: `Insufficient Balance`
      },
      currentState: 0,
      active: {},
      countdownId: '',
      errorMessage: ''
    }
  },
  computed: {
    userInfo () {
      return store.state.app.userInfo
    },
    team () {
      if (store.state.app.userInfo && store.state.app.userInfo.team) {
        return store.state.app.userInfo.team
      }
      return []
    },
    thirtyMinute () {
      let list = []
      for (let i = 0; i < 1000; i += 30) {
        list.push(i)
      }
      return list
    }
  },
  watch: {
    currentState () {
      var vm = this
      if (vm.currentState !== 0) {
        vm.beginCountdown()
      }
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal'
    }),
    buyMinutes () {
      console.log('buy minutes')
      this.status = ''
      this.$emit('closeAdminModal')
      this.$emit('showBillingModal', 'Top Up')
    },
    beginCountdown () {
      var vm = this
      if (vm.countdownId !== '') {
        clearInterval(vm.countdownId)
      }
      vm.countdown = 10
      vm.countdownId = setInterval(reduceTime, 1000)
      function reduceTime () {
        vm.countdown -= 1
        if (vm.countdown === 0) {
          clearInterval(vm.countdownId)
          vm.currentState = 0
        }
      }
    },
    addMinute (email, to, min) {
      var vm = this
      console.log('addminute called')
      const fromUid = vm.userInfo.personal.uid
      const toUid = to
      const seconds = min * 60
      vm.$set(vm.active, email, -1)
      axios.get(CONSTANTS.TRANSFER_BALANCE + '?fromUid=' + fromUid + '&toUid=' + toUid + '&sec=' + seconds).then(function (res) {
        console.log(res)
        if (res.data.status === 200) {
          vm.currentState = 201
        } else if (res.data.status === 401) {
          vm.currentState = 508
        } else {
          vm.currentState = 506
          vm.states[506] = res.data.msg
        }
        vm.$set(vm.active, email, 0)
      }).catch(function (err) {
        vm.currentState = 507
        console.log(err)
        vm.$set(vm.active, email, 0)
      })
    },
    setActive (email) {
      var vm = this
      vm.$set(vm.active, email, 30)
    },
    setInActive (email) {
      var vm = this
      vm.$set(vm.active, email, 0)
    },
    humanLength (timeInSec) {
      return humanLength(timeInSec)
    },
    validateEmail (mail) {
      let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
      return regex.test(mail)
    },
    alreadyInvited (mail) {
      var vm = this
      for (var i in vm.team) {
        // console.log(vm.team[i].email)
        if (vm.team[i].email === mail) {
          return true
        }
      }
      return false
    },
    ownEmail (mail) {
      var vm = this
      if (vm.userInfo.personal.email === mail) {
        return true
      }
      return false
    },
    inviteEmail () {
      var vm = this
      if (vm.email === '') {
        console.log('empty')
        vm.currentState = 500
        return
      } else if (!vm.validateEmail(vm.email)) {
        console.log('incorrect email')
        vm.currentState = 501
        return
      } else if (vm.alreadyInvited(vm.email)) {
        console.log('already invited')
        vm.currentState = 504
        return
      } else if (vm.ownEmail(vm.email)) {
        console.log(`cant invite yourself`)
        vm.currentState = 505
        return
      }
      var update = {}
      update['verified'] = false
      update['email'] = vm.email
      firebase.database().ref(vm.userInfo.personal.uid + '/userInfo/team').push().set(update).then(function () {
        vm.currentState = 200
        vm.email = ''
      }).catch(function (err) {
        console.log(err)
        vm.currentState = 503
      })
      console.log('inviting')
    }
  }
}
</script>

<style lang="less" scoped>
.root {
  width: 580px;
}
</style>
