<template>
  <Modal closePosition="hidden">
    <div class="root p-6">
      <h2 class="font-h font-semibold text-2xl text-primary">
        Correct Transcript
      </h2>

<!--      <div class="mt-2 p-2 text-sm text-primary rounded bg-primary-100">-->
<!--        {{modalProps.startLoc }} {{ modalProps.endLoc}}-->
<!--      </div>-->
      <div
        class="flex items-center px-2 border rounded-lg uploadFile border-primary-400 m-1 p-2"

      >
      <input v-model="modalProps.selectedText" >
      </div>
      <div class="mt-4 flex items-center justify-between">

        <div>
          <Button variant="secondary" size="small" @click="correctTranscript">
            Correct
          </Button>
          <Button size="small" @click="cancel">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'
import { replaceWordAtLocation } from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'

export default {
  components: {
    Modal,
    Button
  },
  data: function() {
    return {
      transcript: ''
    }
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    })
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      closeModal: 'dialogs/closeModal'
    }),
    correctTranscript () {
      replaceWordAtLocation({ start: this.modalProps.startLoc, end: this.modalProps.endLoc }, this.modalProps.selectedText)
      this.closeModal()
    },
    cancel () {
      this.closeModal()
    }
  }
}
</script>
<style lang="less" scoped>
.root {
  width: 560px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  padding-left: 30px;
}

label::before {
  top: -1px !important;
}

label:after {
  top: 8px !important;
}
</style>
